import { useState, useEffect } from 'react'
import "./Timer.css"
function Timer() {
    const calculateTimeLeft = () => {
        let difference = +new Date(`2024-11-15`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className='timer'>
            <h2><div className='time'>{timeLeft.days.toString().padStart(2, '0')} <div className='dot'>:</div> </div> <span>يوم</span> </h2>
            <h2><div className='time'>{timeLeft.hours.toString().padStart(2, '0')} <div className='dot'>:</div> </div> <span>ساعة</span> </h2>
            <h2><div className='time'>{timeLeft.minutes.toString().padStart(2, '0')} <div className='dot'>:</div> </div> <span>دقيقة</span> </h2>

            <h2>{timeLeft.seconds.toString().padStart(2, '0')} <span>ثانية</span> </h2>
        </div>
    )
}

export default Timer
